import { css } from "styled-components";
import { brandSettings } from "../components/StyledComponents/vars";

export const media = Object.keys(brandSettings.breakPoints).reduce(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${brandSettings.breakPoints[label]}) {
        ${css(...args)}
      }
    `;

    return acc;
  },
  {}
);

export const ModalToggle = () => {
  document.getElementsByTagName("HTML")[0].classList.toggle("open-modal");
};

export const ImageName = (image, sep) => {
  if (sep === ".") return image.split(/\.(?=[^\.]+$)/)[1];
  if (sep === "/") return image.split(/\/(?=[^\/]+$)/)[1];
};

export const CommaStringToArray = (string) =>
  string && string.split(",").map((i) => i.trim());
