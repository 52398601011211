import React from "react";
import PropTypes from "prop-types";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import HeroBanner from "../../components/HeroBanner";

const PageBanner = ({
  bgImage,
  bannerContent,
  contentSx,
  containerSx,
  sx,
  contentChildren,
  ...rest
}) => (
  <HeroBanner
    bannerImage={bgImage}
    containerSx={{
      mb: 0,
      mt: "auto",
      ...containerSx,
    }}
    sx={{
      bg: "primary",
      ...sx,
    }}
    {...rest}
  >
    <MarkdownToHtml
      contentChildren={contentChildren}
      heading={bannerContent.heading}
      source={bannerContent.content}
      sx={{
        color: "white",
        h1: { color: "inherit" },
        ...contentSx,
      }}
    />
  </HeroBanner>
);

PageBanner.propTypes = {
  bannerContent: PropTypes.shape({
    content: PropTypes.string,
    heading: PropTypes.string,
  }).isRequired,
  bgImage: PropTypes.string,
  containerSx: PropTypes.shape({}),
  contentChildren: PropTypes.node,
  contentSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

PageBanner.defaultProps = {
  bgImage: "",
  containerSx: {},
  contentChildren: null,
  contentSx: {},
  sx: {},
};

export default PageBanner;
