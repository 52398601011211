import PropTypes from "prop-types";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImg from "gatsby-background-image";
import { camelCase } from "change-case";
import Box from "../../base/Box";
import { ImageName } from "../../helper/helper";

const BgImage = ({ name, page, sx, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      about: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "about" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      bot: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "bot" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      careers: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "careers" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      clients: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "clients" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(height: 100) {
                originalName
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }

      home: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "homePage" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      services: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "services" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      work: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "work" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      industries: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "industries" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      projects: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "projects" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 840) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const isSvg = ImageName(name, ".") === "svg";
  const bgSx = {
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    ...sx,
  };
  return isSvg ? (
    <Box
      sx={{
        backgroundImage: isSvg ? `url('${name}')` : "",
        ...bgSx,
      }}
      {...rest}
    />
  ) : (
    <>
      {data[page].edges.map(({ node }) => {
        const isFixed = node.childImageSharp.fixed;
        const isFluid = node.childImageSharp.fluid;
        if (isFluid && isFluid.originalName === ImageName(name, "/")) {
          return (
            <Box
              key={`${camelCase(node.childImageSharp.fluid.originalName)}`}
              as={BackgroundImg}
              fluid={node.childImageSharp.fluid}
              style={{
                ...bgSx,
              }}
              {...rest}
            />
          );
        }
        if (isFixed && isFixed.originalName === ImageName(name, "/")) {
          return (
            <Box
              key={`${camelCase(node.childImageSharp.fixed.originalName)}`}
              as={BackgroundImg}
              fixed={node.childImageSharp.fixed}
              style={{
                ...bgSx,
              }}
              {...rest}
            />
          );
        }
        return null;
      })}
    </>
  );
};

BgImage.propTypes = {
  name: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

BgImage.defaultProps = {
  sx: {},
};

export default BgImage;
